/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "styled-components"
import Header from "../components/header"
import { GlobalStyle, theme } from "../utils"
import CodeBlock from "../utils/CodeBlock"
import { MDXProvider } from "@mdx-js/react"

const defaultState = {
  theme: "main",
  toggleTheme: {},
}
const ThemeContext = React.createContext(defaultState)

const Layout = ({ children }) => {
  const [theme, setTheme] = useState("main")

  useEffect(() => {
    const theme = JSON.parse(window.localStorage.getItem("theme"))
    if (theme) {
      if (theme === "main") {
        setTheme("main")
      } else {
        setTheme("dark")
      }
    }
  }, [])
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const components = {
    pre: props => <div {...props} />,
    code: CodeBlock,
  }
  const toggleTheme = () => {
    setTheme(prevTheme => {
      let theme = prevTheme === "main" ? "dark" : "main"
      window.localStorage.setItem("theme", JSON.stringify(theme))
      setTheme(theme)
    })
  }
  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        toggleTheme: toggleTheme,
      }}
    >
      <MDXProvider components={components}>
        <GlobalStyle theme={theme} />
        <Header
          theme={theme}
          toggleTheme={toggleTheme}
          siteTitle={data.site.siteMetadata?.title || `Title`}
        />
        <main
          css={css`
            margin-top: 0;
            padding-left: 16px;
            padding-right: 16px;
          `}
        >
          {children}
        </main>
        <footer></footer>
      </MDXProvider>
    </ThemeContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

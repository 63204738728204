import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
// import Prism from "prismjs/components/prism-core"
// import Prism from "prismjs"
import Prism from "prism-react-renderer/prism"
import styled from "styled-components"
// import vsDark from "prism-react-renderer/themes/vsDark"
import theme from "prism-react-renderer/themes/nightOwl"
import { UiText } from "../components"
import Confetti from "react-dom-confetti"
// require("prismjs/themes/prism-tomorrow.css");
// require("prismjs/plugins/line-numbers/prism-line-numbers.css");
;(typeof global !== "undefined" ? global : window).Prism = Prism

require("prismjs/components/prism-verilog")
// require("prismjs/components/prism-csharp")
const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}

export default ({ children, className }) => {
  const [isCopied, setIsCopied] = React.useState(false)
  let language
  if (className) {
    language = className.replace(/language-/, "")
  } else {
    language = "cmd"
  }
  return (
    <Wrapper>
      <Highlight
        Prism={Prism}
        {...defaultProps}
        code={children.trim()}
        language={language}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre className={className} style={{ ...style, padding: "16px" }}>
            <Title>{language}</Title>
            <Button
              onClick={() => {
                copyToClipboard(children.trim())
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 3000)
              }}
            >
              {isCopied ? "🎉 Copied!" : "Copy"}
            </Button>
            {tokens.map((line, i) => (
              <Line
                as="span"
                variant="subContent"
                key={i}
                {...getLineProps({ line, key: i })}
              >
                <LineNo as="span" variant="subContent">
                  {i + 1}
                </LineNo>
                <LineContent as="span" variant="subContent">
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </LineContent>
              </Line>
            ))}
          </Pre>
        )}
      </Highlight>
      <ConfettiWrapper>
        <Confetti active={isCopied} config={config} />
      </ConfettiWrapper>
    </Wrapper>
  )
}

const Title = props => (
  <span
    style={{
      position: "relative",
      color: "#fff",
      background: "#006d77",
      padding: "2px 4px",
      top: 0,
    }}
  >
    {props.children}
  </span>
)

const Wrapper = props => <div style={{ position: "relative" }} {...props} />

const ConfettiWrapper = props => (
  <div style={{ position: "absolute", top: 0, right: 0 }} {...props} />
)

const Button = props => (
  <button
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      border: "none",
      boxShadow: "none",
      textDecoration: "none",
      margin: "16px",
      padding: "8px 12px",
      background: "#E2E8F022",
      // color: "white",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#E2E8F0",
      fontSize: "14px",
      fontFamily: "sans-serif",
      lineHeight: "1",
      zIndex: 999999999999999,
    }}
    {...props}
  />
)

const copyToClipboard = str => {
  const el = document.createElement("textarea")
  el.value = str
  el.setAttribute("readonly", "")
  el.style.position = "absolute"
  el.style.left = "-9999px"
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  overflow: scroll;
  /* position: relative; */
`

const Line = styled(UiText)`
  display: table-row;
  margin: 0;
`

const LineNo = styled(UiText)`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`

const LineContent = styled(UiText)`
  display: table-cell;
`

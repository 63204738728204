import React from "react"
import styled from "styled-components"

import ExtLink from "./ExtLink"

import Github from "../images/github.svg"
import Twitter from "../images/twitter.svg"
import Linkedin from "../images/linkedin.svg"
import Youtube from "../images/youtube.svg"
import Icon from "./Icon"

const SocialLinks = () => {
  return (
    <SocialWrapper>
      <SocialLink>
        {/* <ExtLink href="https://github.com/jsnaruto"> */}
        <Icon
          style={{ display: "flex", alignSelf: "flex-end" }}
          as="a"
          target="_blank"
          href="https://github.com/souleymanedembele"
          icon="IconGithub"
          size="l"
        />
        {/* <SocialImg src={Github} alt="Github" /> */}
        {/* </ExtLink> */}
      </SocialLink>

      <SocialLink>
        {/* <ExtLink href="https://twitter.com/Skill_Road"> */}
        {/* <SocialImg src={Twitter} alt="Twitter" /> */}
        <Icon
          style={{ display: "flex", alignSelf: "flex-end" }}
          as="a"
          // href="https://twitter.com/Skill_Road"
          href="https://twitter.com/souleylight"
          target="_blank"
          icon="IconTwitter"
          size="l"
        />
        {/* </ExtLink> */}
      </SocialLink>

      <SocialLink>
        {/* <ExtLink > */}
        {/* <SocialImg src={Youtube} alt="Youtube channel" /> */}
        <Icon
          style={{ display: "flex", alignSelf: "flex-end" }}
          as="a"
          target="_blank"
          href="https://www.youtube.com/channel/UCIF712HdDs5gARYly1Smn0w"
          icon="IconYoutube"
          size="l"
        />
        {/* </ExtLink> */}
      </SocialLink>

      <SocialLink>
        {/* <SocialImg src={Linkedin} alt="Linkedin" /> */}
        <Icon
          style={{ display: "flex", alignSelf: "flex-end" }}
          href="https://www.linkedin.com/in/souleymanedembele"
          as="a"
          target="_blank"
          icon="IconLinkedin"
          size="l"
        />
      </SocialLink>
    </SocialWrapper>
  )
}

const SocialWrapper = styled.div`
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
  // grid-column: 3 / 4;
  // grid-row: 1;
  // height: 100;
  // padding-right: 8px;

  // @media (max-width: 425px) {
  //   grid-column: 2 / 3;
  // }
`

const SocialImg = styled.img`
  width: 100%;
`

const SocialLink = styled.div`
  list-style-type: none;
  margin: 0 15px;
  // width: 25px;
  // height: 25px;
  display: flex;
  align-items: flex-end;
  transition: 0.3s ease;

  :last-child {
    margin-right: 0;
  }

  :hover {
    transform: translateY(-5px);
    transition: 0.3s ease;
  }
`

export default SocialLinks

import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { UiText, IconButton, Popover } from "."
import SocialLinks from "./SocialLinks"
const NavLink = styled(Link)`
  margin: 0;
  cursor: pointer;
  &:hover {
    /* text-decoration: none; */
  }
  &.current-page {
    text-decoration: underline;
  }
`
const NavigationList = styled.nav`
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;
  align-items: center;
  justify-content: flex-end;
  ul {
    list-style: none;
    display: flex;
    flex: 1;
    height: 100%;
    margin: 0;
    align-items: center;
    justify-content: flex-end;
    li {
      display: none;
      padding: 0 8px;
      margin: 0;
      &.small {
        display: block;
      }
      @media (min-width: 768px) {
        display: block;
        &.small {
          display: none;
        }
      }
    }
  }

  &.no-hide {
    ul {
      list-style: none;
      display: flex;
      flex: 1;
      height: 100%;
      margin: 0;
      align-items: center;
      justify-content: center;
      padding-top: 16px;
      li {
        display: block;
      }
    }
  }
`
const Header = ({ siteTitle, theme, toggleTheme }) => {
  return (
    <header
      css={css`
        width: 100%;
        height: 100%;
        padding-left: 16px;
        padding-right: 16px;
      `}
    >
      <div
        css={css`
          max-width: 960px;
          height: 100%;
          margin: 0 auto;
        `}
      >
        <NavigationList>
          {/* <ul>
            <li> */}
          <Popover
            content={theme === "main" ? "Dark mode" : "Light mode"}
            placement="left"
          >
            <IconButton
              icon={theme !== "main" ? "IconMoon" : "IconSun"}
              onClick={() => {
                toggleTheme()
              }}
            />
          </Popover>
        </NavigationList>
      </div>
      <div
        css={css`
          max-width: 960px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <UiText
          variant="h2"
          as="h2"
          fontSize="22px"
          css={css`
            // padding-left: 8px;
          `}
          margin="0px"
        >
          <Link to="/">{siteTitle}</Link>
        </UiText>
        <NavigationList>
          <ul>
            <UiText as="li">
              <NavLink to="/blog" activeClassName="current-page">
                Blog
              </NavLink>
            </UiText>
            <UiText as="li">
              <NavLink to="/about" activeClassName="current-page">
                About
              </NavLink>
            </UiText>
            <UiText as="li">
              <NavLink to="/contact" activeClassName="current-page">
                Contact
              </NavLink>
            </UiText>
          </ul>
        </NavigationList>
        <NavigationList>
          <SocialLinks />
        </NavigationList>
      </div>

      <NavigationList
        className="no-hide"
        css={css`
          max-width: 960px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          width: 100%;
          @media (min-width: 768px) {
            display: none;
          }
        `}
      >
        <ul>
          <UiText as="li">
            <NavLink to="/blog" activeClassName="current-page">
              Blog
            </NavLink>
          </UiText>
          <UiText as="li">
            <NavLink to="/about" activeClassName="current-page">
              About
            </NavLink>
          </UiText>
          <UiText as="li">
            <NavLink to="/contact" activeClassName="current-page">
              Contact
            </NavLink>
          </UiText>
        </ul>
      </NavigationList>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import { createGlobalStyle } from "styled-components"
import { themes } from "./theme"
export const GlobalStyle = createGlobalStyle`
    :root {
  --f-color-brandPrimary: ${props=> themes[props.theme]['primary']};
  --f-color-brandPrimary--light: ${props=> themes[props.theme]['primary']}; 
  //rgba(47,110,120, 0.9); #006d77
  --f-color-brandPrimary--inactive: #a8dadc;
  --f-color-brandSecondary: #e63946;
  --f-color-brandSecondary--light: #bd4f6c;

  --f-color-icon--white: #fff;
  --f-color-icon--disabled: #dedede;
  --f-color-icon--dark: ${props=> themes[props.theme]['contentColor']}, 
  // #190134;

  --f-color-element--primary: #fff;
  --f-color-element--secondary:   //rgba(47,110,120, 0.9); ${props=> themes[props.theme]['primary']}
  --f-color-element--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-element--tertiary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--tertiary: ${props=> themes[props.theme]['primary']}; 
; 
  //rgba(47,110,120, 0.9);
  --f-color-element--tertiary:   //rgba(47,110,120, 0.9); ${props=> themes[props.theme]['primary']}
  --f-color-element--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-element--tertiary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--tertiary: ${props=> themes[props.theme]['primary']}; 
; 
  //rgba(47,110,120, 0.9);
  --f-color-element--inactive: ${props=> themes[props.theme]['inactive']};
  // #eaeaea
  --f-color-element--error: #ee7d52;
  --f-color-element--success: #5cc689;
  --f-color-element--overlay: #2b1e70;

  --f-color-text--primary: ${props=> themes[props.theme]['contentColor']}, 
  // #190134;
  --f-color-text--secondary:   //rgba(47,110,120, 0.9); ${props=> themes[props.theme]['primary']}
  --f-color-element--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-element--tertiary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--tertiary: ${props=> themes[props.theme]['primary']}; 
; 
  //rgba(47,110,120, 0.9);
  --f-color-text--tertiary:   //rgba(47,110,120, 0.9); ${props=> themes[props.theme]['primary']}
  --f-color-element--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-element--tertiary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--secondary: ${props=> themes[props.theme]['primary']}; 
  --f-color-text--tertiary: ${props=> themes[props.theme]['primary']}; 
; 
  //rgba(47,110,120, 0.9);
  --f-color-text--link: #252d9b;
  --f-color-text--white: #fff;
  --f-color-text--lightGrey: #f2f2f2;
}

:root {
  --f-fontSize--xxs: 0.625rem; /* 10px */
  --f-fontSize--xs: 0.75rem; /* 12px */
  --f-fontSize--s: 0.875rem; /* 14px */
  --f-fontSize--m: 1rem; /* 16px */
  --f-fontSize--l: 1.25rem; /* 20px */
  --f-fontSize--xl: 1.5rem; /* 24px */
  --f-fontSize--xxl: 2rem; /* 32px */
}

:root {
  --f-fontWeight--normal: 400;
  --f-fontWeight--bold: 700;
  --f-fontWeight--black: 800;
}

:root {
  --f-lineHeight--s: 1rem;
  --f-lineHeight--m: 1.4rem;
  --f-lineHeight--l: 1.5rem;
  --f-lineHeight--xl: 2rem;
}

:root {
  --f-iconSize--s: 0.75rem; /* 12px */
  --f-iconSize--m: 1rem; /* 16px */
  --f-iconSize--l: 1.25rem; /* 20px */
}

:root {
  --f-borderRadius--xl: 1.5rem; /* 24px */
  --f-borderRadius--l: 1rem; /* 16px */
  --f-borderRadius--m: 0.5rem; /* 8px */
  --f-borderRadius--s: 0.25rem; /* 4px */
}

:root {
  --f-space--s: 0.25rem; /* 4px */
  --f-space--m: 0.5rem; /* 8px */
  --f-space--l: 0.75rem; /* 12px */
  --f-space--xl: 1rem; /* 16px */
  --f-space--xxl: 1.5rem; /* 24px */
}

:root {
  --f-breakpoint--s: 28.75rem; /* 460px */
  --f-breakpoint--m: 50rem; /* 800px */
  --f-breakpoint--l: 68.75rem; /* 1100px */
}

:root {
  --f-zIndex--Overlay: 100;
  --f-FileUploader-height: 8.125rem; /* 130px */
}

:root {
  --bg-color: ${props=> themes[props.theme]['background']};
  --secondary-bg-color: #eeeeee;
  --heading-color: #5f5f5f;
  --body-color: ${props=> themes[props.theme]['contentColor']};
  --post-color: rgba(0, 0, 0, 0.44);
  --border-color: rgba(0, 0, 0, 0.15);
  --form-border-color: #9f9f9f;
  --form-button-hover-border-color: #000;
  --pre-bg-color: #f9f9fd;
  --nav-text-color: #5a5a5a;
  --tag-color: #424242;
  --blockquote-text-color: #858585;
  --blockquote-border-color: #dfe2e5;
  --thumbnail-height: 15em;
  scroll-padding-top: 100px;
}

* {
  box-sizing: border-box;
  margin: 0;
}


/* html[data-theme="dark"] {
  --bg-color: #292a2d;
  --secondary-bg-color: #2c2d32;
  --heading-color: rgb(169, 169, 179);
  --body-color: rgb(169, 169, 179);
  --post-color: rgba(0, 0, 0, 0.44);
  --border-color: rgb(38, 38, 38);
  --form-border-color: rgb(169, 169, 179);
  --form-button-hover-border-color: #fff;
  --pre-bg-color: rgb(33, 33, 45);
  --nav-text-color: rgb(191, 191, 191);
  --tag-color: rgb(191, 191, 191);
  --blockquote-text-color: #808080;
  --blockquote-border-color: #424242;
} */
/* More info: https://bit.ly/2PsCnzk */

html {
  background-color: var(--bg-color);
  -webkit-font-smoothing: antialiased;
}

body {
  color: var(--body-color);
  /* font-size: 15px; */
  width: 100%;
  /* margin: 0 auto 30px auto; */
  background-color: var(--bg-color);
  /* overflow-x: hidden; */
}
html,
body {
margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 19px; */
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  /* background-color: #292a2d; */
  
}


h1, h2, h3, h4, h5, h6 {
    margin-top: 2.25rem;
    margin-bottom: 0.75rem;
    color: var(--body-color);
    
}
/* p {
  margin: 0;
}
strong {
  color: #222;
}
a {
  text-decoration: none;
} */

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
}
`
export default GlobalStyle

export const themes = {
  dark: {
    primary: "#00A9A5",
    secondary: "#ff9234",
    background: "#333",
    contentColor: "#fffffcc2",
    title: "#eee",
    inactive: "#222",
  },
  main: {
    primary: "rgba(47,110,120, 0.9)",
    secondary: "#0091A0",
    background: "#fff",
    contentColor: "rgba(0, 0, 0, 0.7)", //  '#616163',
    title: "#333",
    inactive: "#eaeaea",
  },
}
